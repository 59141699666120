<template>
	<main class="bg-white">
		<div class="relative flex">
			<!-- Content -->
			<div class="w-full md:w-1/2">
				<div class="min-h-screen h-full flex flex-col after:flex-1">
					<div class="flex-1">
						<div class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"></div>
					</div>

					<div class="max-w-sm mx-auto px-4 py-8">
						<h1 class="text-3xl text-gray-800 font-bold mb-6">Definir contraseña</h1>
						<!-- Form -->
						<form action="" @submit.prevent="submit">
							<div class="space-y-4" v-if="differentPasswords">
								<p>Las contraseñas no coinciden</p>
							</div>
							<div class="space-y-4">
								<div>
									<label class="block text-sm font-medium mb-1" for="password">Contraseña</label>
									<input v-model="password" class="form-input w-full" type="password" autoComplete="off" name="password" id="password" />
								</div>
								<div>
									<label class="block text-sm font-medium mb-1" for="passwordCopy">Repetir contraseña</label>
									<input v-model="passwordCopy" class="form-input w-full" type="password" autoComplete="off" name="passwordCopy" id="passwordCopy" />
								</div>
							</div>
							<div class="flex items-center justify-between mt-6">
								<Button :loading="loading" :disabled="loading" loadingLabel="Enviando...">Enviar</Button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<!-- Image -->
			<div class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
				<img class="object-cover object-center w-full h-full" :src="bgImage" width="760" height="1024" alt="Authentication" />
				<img class="absolute top-1/4 left-0 transform -translate-x-1/2 hidden lg:block" src="./../images/auth-decoration.png" width="218" height="224" alt="Authentication decoration" />
			</div>
		</div>
	</main>
</template>

<script setup lang="ts">
import { auth } from '@/auth'
import router from '@/router/router'
import { user } from '@/user'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import Button from '../components/Button.vue'
import { put } from '../services/api'
const password = ref(null)
const passwordCopy = ref(null)
const differentPasswords = ref(false)
const loading = ref(false)
const route = useRoute()
const product = import.meta.env.VITE_PRODUCT || 'energy'
const bgImage = new URL(`../../../apps/go/images/${product}/auth-bg.png`, import.meta.url).toString()
localStorage.removeItem('auth')
localStorage.removeItem('user')
auth.value = null
user.value = null

const submit = async () => {
	loading.value = true
	differentPasswords.value = password.value.length && passwordCopy.value.length && password.value !== passwordCopy.value
	if (!differentPasswords.value) {
		try {
			await put({ name: 'auth.reset' }, {
				newPassword: password.value,
				newPasswordConfirm: passwordCopy.value,
				token: route.params.token
			})
			password.value = null
			passwordCopy.value = null
			await router.push({ name: 'go.login' })
		} catch (error) {
			alert('Error al internar establecer contraseña.')
		}
	}
	loading.value = false
}
</script>
