import { BadRequestError } from '@/errors/BadRequestError'
import { InternalServerError } from '@/errors/internalServerError'
import { NotFoundError } from '@/errors/notFoundError'
import router from '@/router/router'

export async function compare(options: any) {
	const config = {
		body: JSON.stringify(options),
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		}
	}

	const response = await fetch(`https://ahorreluz.es/api/v1/calculo_api.php`, config)
	const data = await response.json()

	data.ofertas = data.ofertas.map((item: Record<string, any>) => ({
		...item,
		customer: {
			cups: data.cupsCustomer,
			province: data.provinceCustomer,
			zipCode: data.zipCodeCustomer,
			town: data.townCustomer
		}
	}))

	if (response.status === 400) {
		throw new BadRequestError('', [])
	}
	if (response.status === 404) {
		throw new NotFoundError(data.error || '')
	}
	if (response.status === 401) {
		return router.push({ name: 'go.logout' })
	}
	if (response.status === 500 || !response.ok) {
		throw new InternalServerError('Ha ocurrido un error inesperado.')
	}
	return data
}
