<template>
	<div class="max-w-2xl m-auto mt-16" v-if="!props.support || !props.granted || props.error">
		<div class="text-center px-4" v-if="props.error">
			<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 mb-4">
				<svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-6 fill-current" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path></svg>
			</div>
			<h2 class="text-2xl text-gray-800 font-bold mb-2">Error al obtener tu posición</h2>
			<div class="mb-6">Tu navegador no puede obtener la ubicación actual</div>
		</div>
		<div class="text-center px-4" v-if="!props.support">
			<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 mb-4">
				<svg class="w-5 h-6 fill-current" viewBox="0 0 20 24">
					<path class="text-gray-500" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z"></path>
					<path class="text-gray-300" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z"></path>
					<path class="text-gray-400" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z"></path>
				</svg>
			</div>
			<h2 class="text-2xl text-gray-800 font-bold mb-2">Tu navegador no soporta geolocalización</h2>
			<div class="mb-6">Para poder usar esta herramienta es necesario un navegador compatible y actualizado.</div>
		</div>
		<div class="text-center px-4" v-if="!props.granted">
			<div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-red-200 to-red-100 mb-4">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
					<path fill="none" d="M0 0h24v24H0z" />
					<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" />
				</svg>
			</div>
			<h2 class="text-2xl text-gray-800 font-bold mb-2">Habilita permisos de geolocalización</h2>
			<div class="mb-6">La geolocalización es necesaria para obtener tu ubicación.</div>
			<button @click="reloadPage" class="btn bg-indigo-500 hover:bg-indigo-600 text-white">
				<svg class="w-4 h-4 fill-current opacity-50 shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
					<path fill="none" d="M0 0h24v24H0z" />
					<path d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z" />
				</svg>
				<span class="ml-1">Actualizar</span>
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
const props = defineProps({
	support: Boolean,
	granted: Boolean,
	error: Object
})

const reloadPage = () => {
	window.location.reload()
}
</script>
